import React, {createContext, useState} from 'react';



type ThemeContextProviderProps = {
    children: React.ReactNode
}


const ThemeContext = createContext({
    'theme': 'light',
    'setTheme': (theme: string) => {}
});



const ThemeContextProvider = (props: ThemeContextProviderProps) => {
    const [theme, setTheme] = useState('light');

    return (
        <div>
            <ThemeContext.Provider value={{theme: theme, setTheme: setTheme}}>
                {props.children}
            </ThemeContext.Provider>
        </div>
    )
}

export {ThemeContext, ThemeContextProvider};