import {Select, Spin} from "antd";
import React, {useEffect} from "react";
import {bold, boldBlue, boldRed,} from "../../Utils/HtmlUtils";
import {numberToUSD} from "../../API/Utils";
import MedusaOrderButton from "../ActionButtons/MedusaOrderButton";
import {AuthService} from "../../Service/AuthService";
import {UserService} from "../../Service/UserService";

const {Option} = Select;

const PACKING_STATION_MAP = {
    0: {
        id: 0,
        name: "STATION_0",
        type: "Testing",
    },
    1: {
        id: 1,
        name: "STATION_1",
        type: "Only Rack",
    },
    2: {
        id: 2,
        name: "STATION_2",
        type: "Only Rack",
    },
    3: {
        id: 3,
        name: "STATION_3",
        type: "Only Rack",
    },
    4: {
        id: 4,
        name: "STATION_4",
        type: "Only Rack",
    },
    5: {
        id: 5,
        name: "STATION_5",
        type: "Only Rack",
    },
    6: {
        id: 6,
        name: "STATION_6",
        type: "Only Rack",
    },
    7: {
        id: 7,
        name: "STATION_7",
        type: "Only Rack",
    },
    8: {
        id: 8,
        name: "STATION_8",
        type: "Only Rack",
    },
    9: {
        id: 9,
        name: "STATION_9",
        type: "Only Rack",
    },
    10: {
        id: 10,
        name: "STATION_10",
        type: "Only Rack",
    },
    11: {
        id: 11,
        name: "STATION_11",
        type: "Only Rack",
    },
    12: {
        id: 12,
        name: "STATION_12",
        type: "Only Rack",
    },
    13: {
        id: 13,
        name: "STATION_13",
        type: "Only Rack",
    },
    14: {
        id: 14,
        name: "STATION_14",
        type: "Only Rack",
    },
    15: {
        id: 15,
        name: "STATION_15",
        type: "Only Rack",
    },
    16: {
        id: 16,
        name: "STATION_16",
        type: "Only Rack",
    },
    17: {
        id: 17,
        name: "STATION_17",
        type: "Only Rack",
    },
    18: {
        id: 18,
        name: "STATION_18",
        type: "LIGHTS"
    },
    19: {
        id: 19,
        name: "STATION_19",
        type: "RACK",
    },
    20: {
        id: 20,
        name: "STATION_20",
        type: "LIGHTS"
    },

    36: {
        id: 36,
        name: "STATION_36",
        type: "LIGHTS"
    }

} as any


type ToteInfoViewProps = {
    solutionData: any,
    loading: boolean,
    authService: AuthService
}


const ToteInfoView: React.SFC<ToteInfoViewProps> = (props) => {
    const [toteData, setToteData] = React.useState({} as any)

    const [stationLoading, setStationLoading] = React.useState(false)
    const [selectedPackingStationIndex, setSelectedPackingStationIndex] = React.useState(0)


    const getAllPossibleStations = (): number[] => {
        return Object.keys(PACKING_STATION_MAP).map(k => +k)
    }

    const getStationForStationId = (stationId: number): any => {
        if ((0 < stationId && stationId <= 20) || stationId === 36) {
            return PACKING_STATION_MAP[stationId]
        } else {
            return PACKING_STATION_MAP[0]
        }
    }

    const handleStationChange = (value: any): void => {
        setStationLoading(true)
        setSelectedPackingStationIndex(value)
        new UserService(props.authService.getUsername()).changeStationForUser(
            value,
            (data) => {
                console.log(data)

                // Wait a little and refresh page...
                setTimeout(() => {
                    setStationLoading(false);
                    window.location.reload();
                }, 500)
            }
        )
    }

    useEffect(() => {
            if (props.solutionData) {
                // Pack Solution View
                let toteData = props.solutionData.tote_data
                toteData['requestId'] = props.solutionData['request_id']
                toteData['solutionId'] = props.solutionData['solution_id']
                toteData['algorithmName'] = props.solutionData['algorithm_name']
                toteData['algorithmDescription'] = props.solutionData['algorithm_description']
                toteData['order_total_usd'] = props.solutionData['optimisation_request']['order_details']['order_total_usd']

                setToteData(props.solutionData.tote_data)
            }

            setSelectedPackingStationIndex(getStationForStationId(props.authService.packingStationIndex).id)

        }, [props.solutionData, props.authService.packingStationIndex]
    );


    return (
        <div>
            {props.loading ? <Spin/> : <div>
                <table
                    style={{
                        width: '100%',
                        marginBottom: '2%',
                    }}
                >
                    <tbody>
                    <tr
                        style={{
                            borderBottom: '1px solid #1890ff',
                            height: "57px",
                            paddingTop: "2%",
                        }}
                    >
                        <td>{bold('Deposco')}</td>
                        <td>
                            <MedusaOrderButton
                                orderId={toteData['order_id']}
                                user_id={props.authService.getUsername()}
                            />
                        </td>
                    </tr>
                    <tr
                        style={{
                            height: "10px",
                        }}
                    >
                        <td></td>
                        <td>
                        </td>
                    </tr>

                    <tr>
                        <td>{bold('Order ID')}</td>
                        <td>
                            {boldRed(toteData['order_id'])}
                        </td>
                    </tr>

                    <tr>
                        <td>{bold('Tote ID')}</td>
                        <td>
                            {bold(toteData['tote_id'])}
                        </td>
                    </tr>

                    <tr>
                        <td>{bold('Packed By')}</td>
                        <td>
                            {boldBlue(props.authService.getUsername())}
                        </td>
                    </tr>
                    <tr>
                        <td>{bold('Location')}</td>
                        <td>
                            {stationLoading ? <Spin/> : null}
                            <Select
                                style={{width: '80%'}}
                                disabled={stationLoading}
                                defaultValue={selectedPackingStationIndex}
                                onChange={handleStationChange}
                            >

                                {getAllPossibleStations().map((stationId: number) => {
                                    let station = getStationForStationId(stationId)
                                    return (
                                        <Option
                                            key={station.id}
                                            value={station.id}
                                        >Station {station.id}</Option>
                                    )
                                })}
                            </Select>
                        </td>
                    </tr>

                    <tr>
                        <td>{bold('Request ID:')} </td>
                        <td><a
                            href={'/replay_request/' + toteData.requestId}
                            target={'_blank'}
                            rel="noopener noreferrer"
                        >
                            {toteData.requestId ? toteData.requestId.substring(0, 8) : null}
                        </a></td>
                    </tr>

                    <tr>
                        <td>{bold('Solution ID:')} </td>
                        <td>{toteData.solutionId} </td>
                    </tr>

                    <tr>
                        <td>{bold('Algorithm:')} </td>
                        <td>{toteData.algorithmName} </td>
                    </tr>

                    <tr>
                        <td>{bold('Description:')} </td>
                        <td>{toteData.algorithmDescription} </td>
                    </tr>

                    <tr>
                        <td>{bold('Zip Code')}</td>
                        <td>{toteData['destination_zip_code']}</td>
                    </tr>

                    <tr>
                        <td>{bold('Zone')}</td>
                        <td>{toteData['zone']}</td>
                    </tr>

                    <tr>
                        <td>{bold('State')}</td>
                        <td>{toteData['destination_state']}</td>
                    </tr>

                    <tr>
                        <td>{bold('Old Ship Via')}</td>
                        <td>{toteData['original_ship_method']}</td>
                    </tr>

                    <tr>
                        <td>{bold('# Boxes')}</td>
                        <td>{toteData['num_shipments']}</td>
                    </tr>

                    <tr>
                        <td>{bold('# Units')}</td>
                        <td>{toteData['num_items']}</td>
                    </tr>

                    <tr>
                        <td>{bold('Weight')}</td>
                        <td>{toteData['weight_to_ship']} lbs</td>
                    </tr>

                    <tr>
                        <td>{bold('Volume')}</td>
                        <td>{toteData['volume_to_ship']} cb_ft</td>
                    </tr>

                    <tr>
                        <td>{bold('Shipping Cost')}</td>
                        <td>{numberToUSD(toteData['cost'])}</td>
                    </tr>

                    <tr>
                        <td>{bold('Order Total')}</td>
                        <td>{numberToUSD(toteData['order_total_usd'])}</td>
                    </tr>
                    </tbody>

                </table>
            </div>}

        </div>
    )
}

export default ToteInfoView
