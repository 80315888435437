import {Select} from "antd";
import React from "react";
import {bold} from "../../Utils/HtmlUtils";
import {ItemManager} from "../../Packing/ItemManager";
import {SHIP_METHOD_TO_BOX_MAPPING} from "../../API/Utils";

const {Option} = Select;

type ShipMethodSelectionViewProps = {
    itemManager: ItemManager,
    shipmentId: number,
    width: string
}


const ShipMethodSelectionView: React.SFC<ShipMethodSelectionViewProps> = (props) => {

    const virtualBox = props.itemManager.getVirtualBox(props.shipmentId);

    const cleanBoxType = (boxType: string, shipMethod: string) => {
        if (shipMethod === 'USPS Priority Mail' || shipMethod === 'USPS Priority Mail Cubic') {
            return boxType
        } else {
            return SHIP_METHOD_TO_BOX_MAPPING[shipMethod][0]
        }

    }


    return <div>

        <div>
            <div>
                <h2>{bold('Ship Method: ')} </h2>
            </div>

            <div>

                <Select
                    style={{
                        width: props.width,
                    }}
                    onChange={(selectedShipMethod: string) => {
                        props.itemManager.overrideShipMethodForShipmentId(props.shipmentId, selectedShipMethod);
                        props.itemManager.overrideBoxTypeForShipmentId(props.shipmentId, SHIP_METHOD_TO_BOX_MAPPING[selectedShipMethod][0]);
                    }}
                    value={virtualBox.getShipmentBody().ship_method}
                >
                    {Object.keys(SHIP_METHOD_TO_BOX_MAPPING).map((shipMethod, index) => {
                        return <Option key={index} value={shipMethod}>{shipMethod}</Option>
                    })}
                </Select>
            </div>
        </div>

        <div
            style={{
                marginTop: '2%',
                marginBottom: '1%'
            }}
        >
            <div>
                <h2>{bold('Box Type: ')} </h2>
            </div>

            <div>
                <Select
                    style={{
                        width: props.width,
                    }}
                    onChange={(selectedBoxName: string) => {
                        props.itemManager.overrideBoxTypeForShipmentId(props.shipmentId, selectedBoxName);
                    }}
                    value={cleanBoxType(
                        virtualBox.getShipmentBody().box_name,
                        virtualBox.getShipmentBody().ship_method
                    )}
                >

                    {SHIP_METHOD_TO_BOX_MAPPING[virtualBox.getShipmentBody().ship_method].map((boxType: string, index: number) => {
                        return <Option key={index} value={boxType}>{boxType}</Option>
                    })}


                </Select>
            </div>
        </div>
    </div>
}

export default ShipMethodSelectionView
