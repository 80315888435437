import React, {useEffect, useState} from "react";
import {ItemManager} from "../../Packing/ItemManager";
import {backendCall, roundToFixed2} from "../../API/Utils";
import {ShippingBox, VirtualBox} from "../../Packing/Box";
import {bold, boldBlue, boldGreen, boldOrange, boldPurple, boldRed} from "../../Utils/HtmlUtils";
import {Spin} from "antd";


export type LivePriceProgressBarProps = {
    itemManager: ItemManager,
    shipmentId: number,
    numItemsScanned: number,
    boxName: string,
    shipMethodName: string
}

const LivePriceProgressBar: React.SFC<LivePriceProgressBarProps> = (props) => {

    const [liveShippingCost, setLiveShippingCost] = useState(0);
    const [loading, setLoading] = useState(false);

    const virtualBox: VirtualBox = props.itemManager.getVirtualBox(props.shipmentId)
    const shippingBox: ShippingBox = virtualBox.getShippingBox();

    useEffect(() => {
        setLoading(true);

        let shipmentWeight = virtualBox.getWeightOfScannedItems() + shippingBox.getBoxWeight();


        backendCall('/get-packing-live_rate', {
            is_test: '0',
            ship_method_name: props.shipMethodName,
            box_name: props.boxName,
            destination_zone: props.itemManager.getDestinationZone(),
            destination_state: props.itemManager.getDestinationState(),
            weight_lbs: shipmentWeight,

        }, r => r).then((r: any) => {
                try {
                    if (r['result']) {
                        setLiveShippingCost(r['result']['shipping_cost'])
                    }
                } catch (e) {
                    console.log('Error = ', e)
                    alert(`Error fetching stats: ${e}`,)
                } finally {
                    setLoading(false);
                }
            }
        )


    }, [props.numItemsScanned, props.shipMethodName, props.boxName]);

    let originalRate = +props.itemManager.getOriginalShippingCostForShipmentId(props.shipmentId);
    let packerSavings = originalRate - liveShippingCost;

    let originalRateString = roundToFixed2(originalRate);
    let packerSavingsString = roundToFixed2(Math.abs(packerSavings));

    const commonReportStyle = {
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "1px solid black",
        marginBottom: "5px",
        color: "black"
    }

    return <div>

        {loading ? <Spin/> : <div
            style={{
                textAlign: 'center',
            }}
        >
            <div style={commonReportStyle}>
                <span>{bold('Original Rate:')} </span>
                <span>{boldRed('$')}{boldRed(originalRateString)} </span>
            </div>

            <div style={{
                ...commonReportStyle,
                borderBottom: "3px solid black"

            }}>

                <span>{bold('Live Rate:')} </span>
                <span>{boldBlue('- $')}{boldBlue(roundToFixed2(liveShippingCost))}</span>

            </div>


            {packerSavings >= 0 ?
                <div style={{
                    ...commonReportStyle,
                    borderBottom: "0px solid black"
                }}>
                    <span>{bold('Extra Savings:')} </span>
                    <span>{boldGreen(' = $')}{boldGreen(packerSavingsString)}</span>
                </div>

                :

                <div style={{
                    ...commonReportStyle,
                    borderBottom: "0px solid black"
                }}>
                    <span>{bold('Missed Savings:')} </span>
                    <span>{boldPurple('= -$')}{boldPurple(packerSavingsString)}</span>
                </div>
            }
        </div>}

    </div>
}

export default LivePriceProgressBar
