import * as React from 'react';
import "@fontsource/urbanist";

/** Components */
import AppRouter from '../Navigation/Routes';

/** Ant design stylesheet */
import 'antd/dist/antd.css';
import {ThemeContextProvider} from "../ContextProviders/ThemeContextProvider";

const App: React.FC = () => {

    return <div>
        <ThemeContextProvider>
            <AppRouter/>
        </ThemeContextProvider>
    </div>

};

export default App;
