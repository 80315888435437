import {ItemOperation} from "./ItemOperation";
import {ItemOperationResult} from "./ItemOperationResult";
import {Item} from "../Item";
import {ItemOperationStatus} from "./ItemOperationStatus";

export class MoveItemOperation extends ItemOperation {

    private readonly itemUpc: string;
    private readonly fromShipmentId: number;
    private readonly toShipmentId: number;

    constructor(
        itemManager: any,
        packingStationId: string,
        itemUpc: string,
        fromShipmentId: number,
        toShipmentId: number
    ) {
        super(itemManager, packingStationId);
        this.itemUpc = itemUpc.trim();
        this.fromShipmentId = fromShipmentId;
        this.toShipmentId = toShipmentId;
    }

    public execute(resolve: (r: ItemOperationResult) => void): void {
        let itemOperationResult: ItemOperationResult;
        let itemType = this.validateScan(this.itemUpc, resolve);
        if (itemType === null) {
            return;

        }

        let fromShipment = this.getVirtualBox(this.fromShipmentId);
        let toShipment = this.getVirtualBox(this.toShipmentId);
        let item = this.getItems().find((item) => item.isAvailableForMove(
            itemType as string,
            fromShipment,
            toShipment
        ));

        if (item) {
            let destination = this.getVirtualBox(this.toShipmentId);
            let destinationSlot = this.itemManager.getAuthService().getSlotNumberForShipmentId(this.toShipmentId);
            let originSlot = this.itemManager.getAuthService().getSlotNumberForShipmentId(this.fromShipmentId);

            item.moveToDestination(destination.getShippingBox())

            let remainingQuantity = item.getCurrentCountAtOrigin();
            let originalQuantity = item.getOriginalCountAtOrigin();
            let scannedQuantity = originalQuantity - remainingQuantity;
            let numberOfScannedItemsAtOrigin = fromShipment.getNumberOfScannedItemsForType(itemType);
            let numberOfScannedItemsAtDestination = toShipment.getNumberOfScannedItemsForType(itemType);

            itemOperationResult = new ItemOperationResult(
                item,
                ItemOperationStatus.SUCCESS,
                remainingQuantity,
                originalQuantity,
                scannedQuantity,
                'SUCCESS: MOVED ITEM',
                [
                    `MOVED ITEM: 1 x ${itemType}`,
                    `UPC: ${this.itemUpc}`,
                    `FROM: ${originSlot} (${numberOfScannedItemsAtOrigin})`,
                    `INTO: ${destinationSlot} (${numberOfScannedItemsAtDestination})`,
                    `TOTAL QUANTITY: ${originalQuantity}`,
                    `UN-SCANNED QUANTITY: ${remainingQuantity}`,
                ],
                '',
                []
            )


            this.getPackingStationService().moveItemSuccess(
                this.toShipmentId,
                this.fromShipmentId,
                this.toShipmentId,
                numberOfScannedItemsAtOrigin,
                numberOfScannedItemsAtDestination,
                itemType,
                this.itemUpc,
                r1 => {
                    this.actionLoggingService.logMoveItemAction(
                        itemType as string,
                        this.fromShipmentId,
                        this.toShipmentId,
                        r2 => resolve(itemOperationResult)
                    )
                })

        }


        // This means that all items have been moved... which means we have overs canned
        // Let's find a random item of the type to get the counts and return the error
        else {
            item = this.getItems().find((item) => item.getType() === itemType) as Item;
            let remainingQuantity = item.getCurrentCountAtOrigin();
            let originalQuantity = item.getOriginalCountAtOrigin();
            let scannedQuantity = item.getCurrentCountAtDestination();
            let optimalDestinationShipmentId = item.getOptimalShipmentId()

            itemOperationResult = new ItemOperationResult(
                item,
                ItemOperationStatus.IMPOSSIBLE_MOVE,
                remainingQuantity,
                originalQuantity,
                scannedQuantity,
                '',
                [],
                'FAIL: BAD MOVE',
                [
                    `ITEM: ${itemType}`,
                    `UPC: ${this.itemUpc}`,
                    `QUANTITY: ${scannedQuantity} of ${originalQuantity} already scanned`,
                    `REMAINING: ${remainingQuantity} of ${originalQuantity} remaining`,
                    `SOLUTION 1: Are you moving the wrong item?`,
                    `SOLUTION 2: Are you moving the item from a wrong box?`,
                ]
            )

            this.getPackingStationService().overScannedItem(
                optimalDestinationShipmentId,
                originalQuantity,
                scannedQuantity,
                itemType,
                this.itemUpc,
                    r1 => {
                        this.actionLoggingService.logScanErrorAction(
                            `UPC = ${this.itemUpc}, ITEM = ${itemType}`,
                            itemOperationResult.shortErrorMessage,
                            r2 => resolve(itemOperationResult)
                        )
                    }
            )

        }


    }


}