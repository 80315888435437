import React, {useEffect, useState} from "react";
import {Button, Table, Select, Slider, Icon, Checkbox, Input} from "antd";
import {
    authSuccessful,
    DEFAULT_ALL_BOX_TYPES, roundToFixed2, roundToFixed4,
} from "../../API/Utils";
import useAuthUser from "../../Hooks/auth/useAuthUser";
import {SOLUTUION_TABLE_COLUMNS} from "../../Components/TableColumns/SolutionTableColumns";
import {getApiEnv} from "../../Utils/ApiEnv";
import ToteInfoView from "../../Components/Shared/ToteInfoView";
import PackerStatsView from "../../Components/Shared/PackerStatsView";
import {bold} from "../../Utils/HtmlUtils";
import {AuthService} from "../../Service/AuthService";
import {Item} from "../../Packing/Item";

const {Option} = Select;

interface OptimizeToteRabotProps {
    parentRequestId: string
}

const items = [
    {
        'item_id': 'ENERGIZER_MAX',
        'product_name': 'ENERGIZER_MAX'.replace('_', ' '),
        'width_inches': 2.75,
        'height_inches': 2.5,
        'length_inches': 5.5,
        'weight_lbs': (40.0 / 16),
        'volume_cubic_feet': (2.75 * 2.5 * 5.5) / 1728,
        'category': 'Hardware Battery',
        'description_tags': ['HAZMAT', 'UN3481'],
        'upc': '039800006080',
        'amazon_link': 'https://amazon.com/dp/B000JCCT9Y',
        'image_thumbnail': 'https://i.imgur.com/xj8pacH.png'
    },
    {
        'item_id': 'POWERADE_BLUE',
        'product_name': 'POWERADE_BLUE'.replace('_', ' '),
        'width_inches': 3.5,
        'height_inches': 8.0,
        'length_inches': 3.5,
        'weight_lbs': (31.0 / 16),
        'volume_cubic_feet': (3.5 * 8.0 * 3.5) / 1728,
        'category': 'Energy Drink',
        'description_tags': ['LIQUID'],
        'upc': '049000079357',
        'amazon_link': 'https://amazon.com/dp/B07Z8C7C3N',
        'image_thumbnail': 'https://i.imgur.com/lSmNBOi.png'
    },
    {
        'item_id': 'POWERADE_RED',
        'product_name': 'POWERADE_RED'.replace('_', ' '),
        'width_inches': 3.5,
        'height_inches': 8.0,
        'length_inches': 3.5,
        'weight_lbs': (31.0 / 16),
        'volume_cubic_feet': (3.5 * 8.0 * 3.5) / 1728,
        'category': 'Energy Drink',
        'description_tags': ['LIQUID'],
        'upc': '049000079388',
        'amazon_link': 'https://amazon.com/dp/B07Z8BTP4Y',
        'image_thumbnail': 'https://i.imgur.com/6Wx9NaD.png'
    },
    {
        'item_id': 'HAIRSPRAY_EXTRA_HOLD',
        'product_name': 'HAIRSPRAY_EXTRA_HOLD'.replace('_', ' '),
        'width_inches': 3.0,
        'height_inches': 11.25,
        'length_inches': 3.0,
        'weight_lbs': (20.0 / 16),
        'volume_cubic_feet': (3.0 * 11.25 * 3.0) / 1728,
        'category': 'Hairspray',
        'description_tags': ['HAZMAT'],
        'upc': '022400622309',
        'amazon_link': 'https://amazon.com/dp/B00IUY6IXQ',
        'image_thumbnail': 'https://i.imgur.com/GtA1VYN.png'
    },
]

const OptimizeToteRabotContainer = (props: OptimizeToteRabotProps) => {
    const [toteId, setToteId] = useState<string>('Parcel_Forum_Demo')
    const [realtime, setRealtime] = useState<boolean>(true)


    const [itemQuantities, setItemQuantities] = useState<any>({
        'ENERGIZER_MAX': 10,
        'POWERADE_BLUE': 6,
        'POWERADE_RED': 6,
        'HAIRSPRAY_EXTRA_HOLD': 6
    })

    const [searchLoading, setSearchLoading] = useState<boolean>(false)
    const [toteDataLoading, setToteDataLoading] = useState<boolean>(false)
    const [allowItemSplitting, setAllowItemSplitting] = useState<boolean>(true);

    const {authUser, authPending} = useAuthUser()
    const [fillRate, setFillRate] = useState<number>(90)

    const maxNumAllowedBoxes = 10;
    const allBoxTypes = DEFAULT_ALL_BOX_TYPES;
    const [selectedBoxTypes, setSelectedBoxTypes] = useState<Array<string>>([
        '1/2 cube',
        '12Cube'
    ]);

    let solutionsTemp: any = [];
    let numSolutions = 0;
    let bestCost = 99999999999;


    const [solutions, setSolutions] = useState<any[]>([])

    const updateSolutions = (newSolution: any) => {
        solutionsTemp = [...solutionsTemp, newSolution]

        // sort solutions by shipping cost
        solutionsTemp.sort(
            (a: any, b: any) => a.tote_data.cost - b.tote_data.cost
        )

        setSolutions(solutionsTemp.map((solution: any, index: number) => {
            return {
                ...solution,
                key: index
            }
        }))
    }

    useEffect(() => {
        setToteDataLoading(true);
        if (authSuccessful(authUser, authPending)) {
            if (props.parentRequestId) {
                setToteId('XXX');
                setRealtime(false);
            }
            setToteDataLoading(false);

        }

    }, [authUser, authPending, props.parentRequestId]);

    const handleBoxTypeSelection = (selectedOptions: any) => {
        setSelectedBoxTypes(selectedOptions)
    }

    const handleKeyPress = (e: any) => {
        if (e.key === 'Enter' && !buttonDisabled()) {
            handleClick()
        }
    }

    const buttonDisabled = (): boolean => {
        return searchLoading || !toteId || selectedBoxTypes.length === 0
    }


    const handleClick = () => {
        setSearchLoading(true);
        let wsAPIUrl = `${getApiEnv().websocketEndpoint}/solution-search-rabot`;
        const socket = new WebSocket(wsAPIUrl);

        // Set all variables to default
        solutionsTemp = [];
        numSolutions = 0;
        bestCost = 99999999999;
        setSolutions([]);


        socket.onopen = () => {
            socket.send(JSON.stringify({
                search_params: {
                    box_fill_rate: fillRate,
                    allowed_box_types: selectedBoxTypes,
                    realtime: realtime,
                    tote_id: toteId,
                    order_id: 'MD_XYZ_ABC',
                    request_id: props.parentRequestId,
                    random_seed: 39,
                    max_search_time: 60,
                    allow_item_splitting: allowItemSplitting,
                    packer: new AuthService(authUser).getUsername(),
                    items: items.map((itemRaw: any) => {
                        let itemQuantity = itemQuantities[itemRaw.item_id];
                        let item = Item.fromRaw(itemRaw);

                        return {
                            item_id: itemRaw.item_id,
                            quantity: itemQuantity,
                            item_upc: itemRaw.upc,
                            width_inches: item.getWidthInches(),
                            height_inches: item.getHeightInches(),
                            length_inches: item.getLengthInches(),
                            weight_oz_item: item.getWeightLbs() * 16,
                            volume_cubic_feet: item.getVolumeCubicFeet(),
                            category: item.getCategory(),
                            description_tags: item.getDescriptionTags(),
                            brand_name: item.getBrandName(),
                            variant_name: item.getVariantName(),
                            product_name: item.getProductName(),
                            image_thumbnail: item.getImageThumbnail(),
                        }
                    })
                }
            }))

        }

        socket.addEventListener('message', ev => {
            try {
                const eventData = JSON.parse(ev.data);
                let optimisationDone = eventData.optimisation_done;

                if (optimisationDone) {

                    if (eventData.optimisation_status === 'success') {

                        setSearchLoading(false);
                        setToteDataLoading(false);
                        console.log('Search completed...')
                        socket.close()
                    } else {
                        setSearchLoading(false);
                        setToteDataLoading(false);
                        alert('[ERROR] -> ' + eventData.message)
                        socket.close()
                    }

                    return
                } else if (eventData.new_solution) {
                    setToteDataLoading(false);

                    // Only update if new solution is better than the last
                    // or if there are no solutions
                    // console.log(eventData.new_solution)

                    if (numSolutions === 0 || eventData.new_solution.tote_data.cost < bestCost) {
                        numSolutions = numSolutions + 1
                        bestCost = eventData.new_solution.tote_data.cost

                        let result = eventData.new_solution
                        setToteId(result['tote_data']['tote_id'])

                    }
                    updateSolutions(eventData.new_solution)


                    return
                } else if (eventData.connection_status === 'success') {
                    setSearchLoading(true);
                } else {
                    alert('Unknown Error ' + JSON.stringify(eventData))
                    setSearchLoading(false);
                }
            } catch (e) {
                setSearchLoading(false);
                console.log(e)
                alert(e)
            }
        })
    }


    return (
        <div
            onKeyPress={handleKeyPress}
            tabIndex={0}
            style={{
                width: "90%",
                marginLeft: "5%",
                marginTop: "0px",
                textAlign: "center",
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                < div
                    style={{
                        width: "25%",
                        border: "1px solid black",
                        borderRadius: "10px",
                    }}
                >
                    <ToteInfoView
                        solutionData={solutions[0]}
                        loading={toteDataLoading}
                        authService={new AuthService(authUser)}
                    />

                </div>

                <div
                    style={{
                        width: "45%",
                        border: "1px solid black",
                        borderRadius: "10px",
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                    }}
                >
                    <div
                        style={{
                            borderBottom: '1px solid #1890ff',
                            height: "57px",
                            paddingTop: "1%",
                        }}
                    >
                        <h1>Parcel Forum Demo</h1>
                    </div>

                    <div
                        style={{
                            width: '95%',
                            marginLeft: '2.5%',
                            marginBottom: '5%',
                            marginTop: '5%'
                        }}
                    >
                        <div>
                            <h2>Items</h2>
                            <table
                                width={'100%'}
                            >
                                <thead>
                                <tr>
                                    <td style={{border: '1px solid black'}}>{bold('SKU')}</td>
                                    <td style={{border: '1px solid black'}}>{bold('Dims (W x H x L)')}</td>
                                    <td style={{border: '1px solid black'}}>{bold('Mass')}</td>

                                </tr>
                                </thead>
                                <tbody>
                                {
                                    // Sorting aggregated items by volume descending...
                                    // feels overcomplicated but that's what we re doing here... haha!
                                    items.map((itemRaw: any, index) => {


                                        let itemQuantity = itemQuantities[itemRaw.item_id];
                                        let item = Item.fromRaw(itemRaw);

                                        return <tr key={index}>

                                            {/*Item Row*/}
                                            <td style={{
                                                border: '1px solid black'
                                            }}>
                                                <div>

                                                    <div>
                                                        <Input
                                                            value={itemQuantity}
                                                            onChange={(e) => {
                                                                let newQuantity = e.target.value;
                                                                setItemQuantities({
                                                                    ...itemQuantities,
                                                                    [itemRaw.item_id]: +newQuantity
                                                                })
                                                            }}
                                                            style={{
                                                                textAlign: 'center',
                                                                width: '30%',
                                                                marginLeft: '10%'
                                                            }}

                                                        ></Input> x
                                                    </div>
                                                    <p>{bold(item?.getProductName())} {item?.getVariantName()}</p>

                                                </div>
                                                <img
                                                    style={{
                                                        width: '50px',
                                                        height: '50px',
                                                        marginLeft: '1%',
                                                        border: '1px solid orange',
                                                        borderRadius: '50%'
                                                    }}
                                                    src={item?.getImageThumbnail()}
                                                    alt={''}
                                                />


                                            </td>

                                            {/*Item Dimensions*/}
                                            <td style={{border: '1px solid black'}}>
                                                <p>{bold(item?.getWidthInches())} in x</p>
                                                <p>{bold(item?.getHeightInches())} in x</p>
                                                <p>{bold(item?.getLengthInches())} in</p>
                                            </td>

                                            {/*Item Weight*/}
                                            <td style={{border: '1px solid black'}}>
                                                <p>{bold('Weight: ')} {roundToFixed2(item?.getWeightLbs() as number)} lbs</p>
                                                <p>{bold('Volume: ')} {roundToFixed4(item?.getVolumeCubicFeet() as number)} cbft</p>
                                            </td>

                                        </tr>
                                    })
                                }

                                </tbody>
                            </table>
                        </div>

                    </div>


                    <div
                        style={{
                            width: '75%',
                            marginLeft: '12.5%',
                            marginBottom: '5%',
                        }}
                    >
                        <h2>Box Types</h2>
                        <Select
                            mode="multiple"
                            style={{width: '100%'}}
                            placeholder="Please select"
                            defaultValue={selectedBoxTypes}
                            onChange={handleBoxTypeSelection}
                        >
                            {allBoxTypes.map((boxType: string) => (
                                <Option
                                    key={boxType}
                                    disabled={
                                        selectedBoxTypes.length >= maxNumAllowedBoxes
                                            ? selectedBoxTypes.includes(boxType)
                                                ? false
                                                : true
                                            : false
                                    }

                                >{boxType}</Option>
                            ))}
                        </Select>
                    </div>

                    <div
                        style={{
                            width: '75%',
                            marginLeft: '12.5%'
                        }}
                    >
                        <h2>Box Fill Rate = {fillRate}%</h2>
                        <Slider
                            value={fillRate}
                            onChange={(e: any) => setFillRate(e)}
                            disabled={false}
                        />
                    </div>

                    <div
                        style={{
                            marginBottom: '5%',
                            display: 'flex',
                            justifyContent: 'space-evenly',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',

                            }}
                        >
                            <label>Allow Item Splitting</label>
                            <Checkbox
                                onChange={(e) => setAllowItemSplitting(e.target.checked)}
                                checked={allowItemSplitting}
                            ></Checkbox>
                        </div>
                        <div>
                            <Button
                                type={"primary"}
                                onClick={handleClick}
                                disabled={buttonDisabled()}
                                loading={searchLoading}
                            >
                                Find Shipments <Icon type={'search'}></Icon>
                            </Button>
                        </div>
                        <div></div>
                    </div>
                </div>


                <div
                    style={{
                        width: "25%"
                    }}
                >
                    <PackerStatsView
                        authUser={authUser}
                    />
                </div>
            </div>

            <div
                style={{
                    marginTop: "10px",

                }}
            >
                <Table
                    dataSource={solutions}
                    columns={SOLUTUION_TABLE_COLUMNS}
                    loading={searchLoading && solutions.length === 0}
                    pagination={{pageSize: 25}}

                />
            </div>
        </div>


    )

}

export default OptimizeToteRabotContainer;
