import * as React from "react";
import {Menu, Icon, Switch} from 'antd';
import {useHistory, useLocation} from "react-router-dom";
import {useEffect} from "react";
import {ThemeContext} from "../../ContextProviders/ThemeContextProvider";

type NavigationProps = {}

const Navigation: React.SFC<NavigationProps> = (props) => {
    const history = useHistory();
    const location = useLocation()

    const [selectedMenuItems, setSelectedMenuItems] = React.useState<Array<any>>([''])

    const {theme, setTheme} = React.useContext(ThemeContext);

    useEffect(() => {
        if (location.pathname) {
            let path = location.pathname.split('/')[1]
            if (!path) {
                path = 'shipment_tracking'
            }
            setSelectedMenuItems([path])
        }
    }, [location.pathname])

    const handleClick = (e: any) => {
        const selection = e.key

        if (selection !== 'theme') {
            setSelectedMenuItems([selection])
            history.push(`/${selection}`)
        }

    }

    return <div
        style={{
            width: "90%",
            marginLeft: "5%",
        }}
    >
        <Menu onClick={handleClick} selectedKeys={selectedMenuItems} mode="horizontal">

            <Menu.Item key="theme">
                <Switch
                    checkedChildren="Light"
                    unCheckedChildren="Dark"
                    checked={theme === 'light'}
                    onClick={(e) => {
                        let newTheme = theme === 'light' ? 'dark' : 'light';
                        setTheme(newTheme);
                    }}
                />
            </Menu.Item>


            <Menu.Item key="home">
                <Icon type="home"/>
                Home
            </Menu.Item>

            <Menu.Item key="shipping-file-tool">
                <Icon type="tool"/>
                Shipping File Tool
            </Menu.Item>

            <Menu.Item key="login">
                <Icon type="user"/>
                Login
            </Menu.Item>

            <Menu.Item key="reset-items">
                <Icon type="undo"/>
                Reset Items
            </Menu.Item>

            <Menu.Item key="optimize_tote">
                <Icon type="rocket"/>
                Box Optimiser
            </Menu.Item>
            <Menu.Item key="packing_stats">
                <Icon type="bar-chart"/>
                Packing Stats
            </Menu.Item>
            <Menu.Item key="request_history">
                <Icon type="history"/>
                Order History
            </Menu.Item>
            <Menu.Item key="view_simulation">
                <Icon type="experiment"/>
                USPS Analysis
            </Menu.Item>
            <Menu.Item key="shipment_tracking">
                <Icon type="global"/>
                Shipment Tracking
            </Menu.Item>
            <Menu.Item key="check_ship_via">
                <Icon type="eye"/>
                Check Ship Via
            </Menu.Item>
        </Menu>
    </div>
}

export default Navigation
